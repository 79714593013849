import SkinsNav from "../Components/SkinsNav";
import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from "../Components/ScrollToTop";
import Home from "../Pages/Home";
import HowToPlay from "../Pages/HowToPlay";
import GameRules from "../Pages/GameRules";
import SkinsFooter from "../Components/SkinsFooter";
import MySkins from "../Pages/MySkins";
import Matches from "../Pages/Matches";
import CookiePolicy from "../Pages/CookiePolicy";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TermsConditions from "../Pages/TermsConditions";
import ResponsibleGambling from "../Pages/ResponsibleGambling";
import Blogs from "../Pages/Blogs";
import Register from "../Pages/Register";
import DocumentVerification from "../Pages/DocumentVerification";
// import Euro from "../Pages/Euro";
import Payment from "../Pages/Payment";
import { useMediaQuery } from 'react-responsive'
import TempPayment from "../Pages/TempPayment";



const SkinsRoutes = () => {

    const isMobile = useMediaQuery({ maxWidth: 1224 })

    const render = () => {
        return (
            <BrowserRouter>
                {/*<Loader loading={loading}/>*/}
                <ScrollToTop>
                   <SkinsNav isMobile={isMobile}/> 
                    <Routes>
                        <Route exact path="/" element={<Home isMobile={isMobile}/>}/>
                        <Route exact path="/how-to-play" element={<HowToPlay isMobile={isMobile}/>}/>
                        <Route exact path="/game-rules" element={<GameRules isMobile={isMobile}/>}/>

                        <Route exact path="/my-skins" element={<MySkins isMobile={isMobile}/>}/>
                        <Route exact path="/matches/:skinId/:weekId?" element={<Matches isMobile={isMobile}/>}/>
                        <Route exact path="/blog/:slug?" element={<Blogs isMobile={isMobile}/>}/>

                        <Route exact path="/cookie-policy" element={<CookiePolicy/>}/>
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route exact path="/terms-and-conditions" element={<TermsConditions/>}/>
                        <Route exact path="/responsible-gambling" element={<ResponsibleGambling/>}/>
                        <Route exact path="/contact-us" element={<SkinsFooter isMobile={isMobile}/>}/>
                        <Route exact path="/register" element={<Register isMobile={isMobile}/>}/>
                        <Route exact path="/payment" element={<Payment isMobile={isMobile}/>}/>
                        {/*<Route exact path="/euro-24" element={<Euro isMobile={isMobile}/>}/>*/}
                        {/*<Route exact path="/euro-24/:tab/:id?" element={<Euro isMobile={isMobile}/>}/>*/}
                        {/*<Route exact path="/euro-24/:tab/:id/:matchDayId?" element={<Euro isMobile={isMobile}/>}/>*/}
                        <Route exact path="/temp-payment" element={<TempPayment isMobile={isMobile}/>}/>

                        <Route exact path="/document-verification" element={<DocumentVerification isMobile={isMobile}/>}/>
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        );
    }

    return render();
}

export default SkinsRoutes;
