import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faWhatsapp,
    faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {Parallax} from "react-parallax";
import {useEffect, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import React from 'react'
import ReactPlayer from 'react-player'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {HomeSection4Images} from "../assets/data/HomeSection4Images";
import HowToPlayComponent from "../Components/HowToPlayComponent";
import SkinsFooter from "../Components/SkinsFooter";
import {Helmet} from "react-helmet";
// import CampaignModal from "../Components/CampaignModal";
import {INSTAGRAM_LINK, TWITTER_LINK, WHATSAPP_LINK} from "../Axios/EndPoints";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-1B6PS1ZN0L');

const Home = (props) => {
    const {isMobile} = props;
    const sliderRef = useRef(null);

    // const [showCampaignModal, setShowCampaignModal] = useState(true);

    /*const handlePrev = useCallback(() => {
        // if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        // if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);*/

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);


    const handleGooglePlay = () =>{
        ReactGA.event(
            "play_store_button_click",
        );
    }
    const handleAppStore = () =>{
        ReactGA.event(
            "app_store_button_click",
        );
    }

    const render = () => {
        return (
            <div>
                <Helmet>
                    <title>Sportskins - Home</title>
                    <meta name="description" content="Sportskins- Home"/>
                </Helmet>
                {renderSection1()}
                {renderSection3()}
                {renderSection4()}
                {/*{isMobile ? renderSection2() : renderSection3()}*/}
                {/*{isMobile ? renderSection3() : renderSection2()}*/}
                {renderSection2()}
                {<SkinsFooter isMobile={isMobile}/>}
            </div>
        );
    };

    const renderSection1 = () => {
        return (
            <section className={`home-section-1-bg  position-relative`} style={{height: isMobile ? "143vh":"100vh"}}>
                <Container fluid={true} className={"position-relative"}>
                    <Row>
                        <Col xxl={7} xl={7} lg={12} md={12} sm={12} className="p-xxl-3 p-xl-3 p-lg-3 p-md-3 p-sm-1">
                            <h2 className={isMobile?`mb-0 mt-5 text-skins-red syne text-center font-20 fw-bold`:`mb-0 mt-5 text-skins-red syne text-center font-32 fw-bold`}>
                                WELCOME TO
                            </h2>
                            <h1 className={`dokyo mt-2 text-center fw-bold ${isMobile ? "heading-home-skins-mobile":"heading-home-skins"}`}>
                                <span className={'text-white'}>SPORT</span>
                                <span className={'text-skins-teal '}>SKINS</span>
                            </h1>
                            <div className={isMobile?'font-18 cabin text-white text-center mt-3':'font-32 cabin text-white text-center mt-5'}
                                 style={{letterSpacing: '1.2px', lineHeight: '1.6em'}}>
                                The home of biggest last fan standing competition in the world.
                            </div>
                            <div>
                            {
                                isMobile ? (
                                    <>
                                        <Col 
                                            xxl={4} 
                                            xl={5} 
                                            lg={12} 
                                            md={12} 
                                            sm={false} 
                                            className="d-flex flex-column align-items-center justify-content-center mt-4"
                                        >
                                            <div style={{ width: '100%', height: '50%', overflow: 'hidden', marginBottom: '10px' }}>
                                                <ReactPlayer
                                                    className="react-player-video-wrapper" 
                                                    url='https://cdn.sportskins.uk/videos/sportskins-2024-landscape.mp4'
                                                    controls={true}
                                                    playing={true}
                                                    muted={true}
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>

                                            <Row className="text-white d-flex justify-content-center align-items-center w-100 mt-3" md={6}>
                                                <ul className="list-unstyled d-flex flex-row justify-content-around m-0 p-0 w-100 ">
                                                    <li className="">
                                                        <a href={INSTAGRAM_LINK} target={'_blank'} rel={'noreferrer'}>
                                                            <FontAwesomeIcon icon={faInstagram} size={'2x'} className={'text-white'} />
                                                        </a>
                                                    </li>
                                                    <li className="">
                                                        <a href={WHATSAPP_LINK} target={'_blank'} rel={'noreferrer'}>
                                                            <FontAwesomeIcon icon={faWhatsapp} size={'2x'} className={'text-white'} />
                                                        </a>
                                                    </li>
                                                    <li className="">
                                                        <a href={TWITTER_LINK} target={'_blank'} rel={'noreferrer'}>
                                                            <FontAwesomeIcon icon={faXTwitter} size={'2x'} className={'text-white'} />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Row>
                                        </Col>

                                    </>
                                ) : (
                                    <></>
                                )
                                
                                }
                            </div>
                            <div className= {`${isMobile ? 'text-center mt-1 pt-5' : 'text-center mt-5 pt-5'}`}>
                                <Row className={'text-center justify-content-center d-flex'}>
                                    <Col className={'mt-2 text-sm-center text-md-end'}>
                                        <a
                                            href={'https://play.google.com/store/apps/details?id=uk.sportskins'} target={'_blank'} rel={'noreferrer'}
                                            onClick={handleGooglePlay}
                                        >
                                            <img src={require('../assets/images/home/google_play.png')} alt={'Home Google Play'}/>
                                        </a>
                                    </Col>
                                    <Col className={'mt-2 text-sm-center text-md-start'}>
                                        <a
                                            onClick={handleAppStore}
                                            href={'https://apps.apple.com/gb/app/sportskins-football-betting/id1668307911'} target={'_blank'} rel={'noreferrer'}>
                                            <img src={require('../assets/images/home/app_store.png')} alt={'Home App Store'}/>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <div className={`text-center ${isMobile ? "pt-2":"mt-5 pt-5"}`}>
                                <Row className={'text-center justify-content-center d-flex'}>
                                    <Col className={'mt-2 text-sm-center text-md-center'}>
                                        <a href={'https://www.begambleaware.org/'} target={'_blank'} rel={'noreferrer'}>
                                            <img src={require('../assets/images/wp-content/uploads/2021/11/Gamble-aware-Logo-footer.png')} className={'img-fluid'} alt="be_gamble_aware_home_image"/>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        {
                        isMobile ? (
                            <></>
                        ) : (
                            <>
                                <Col 
                                    xxl={4} 
                                    xl={5} 
                                    lg={12} 
                                    md={12} 
                                    sm={false} 
                                    className="d-flex flex-column align-items-center justify-content-center"
                                >
                                    <div style={{ width: '100%', height: '50%', overflow: 'hidden', marginBottom: '10px' }}>
                                        <ReactPlayer
                                            className="react-player-video-wrapper" 
                                            url='https://cdn.sportskins.uk/videos/sportskins-2024-landscape.mp4'
                                            controls={true}
                                            playing={true}
                                            muted={true}
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>

                                    <Row className="text-white d-flex justify-content-center align-items-center w-100 mt-5" md={6}>
                                        <ul className="list-unstyled d-flex flex-row justify-content-around m-0 p-0 w-100 ">
                                            <li className="">
                                                <a href={INSTAGRAM_LINK} target={'_blank'} rel={'noreferrer'}>
                                                    <FontAwesomeIcon icon={faInstagram} size={'2x'} className={'text-white'} />
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href={WHATSAPP_LINK} target={'_blank'} rel={'noreferrer'}>
                                                    <FontAwesomeIcon icon={faWhatsapp} size={'2x'} className={'text-white'} />
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href={TWITTER_LINK} target={'_blank'} rel={'noreferrer'}>
                                                    <FontAwesomeIcon icon={faXTwitter} size={'2x'} className={'text-white'} />
                                                </a>
                                            </li>
                                        </ul>
                                    </Row>
                                </Col>

                            </>
                        )

                        }

                    </Row>
                </Container>
            </section>
        );
    };
    

    const renderSection2 = () => {
        return (
            <section className={`home-section-2-bg p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0 mb-4`}>
                <Container>
                    <Row>
                        {
                            isMobile &&
                            <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
                                <Parallax
                                    bgImage={require('../assets/images/wp-content/uploads/elementor/thumbs/iphone-double-ss@3x-q3sr5jl8zsw3vha5u2jusegflh0bu2xoehbbvt2mhq.png')}>
                                    <div style={{height: '570px'}}>
                                    </div>
                                </Parallax>
                            </Col>
                        }
                        <Col xxl={6} xl={6} lg={12} md={12} sm={12}>
                            <div className={`mb-0 mt-5 text-skins-teal cabin text-start font-48 fw-bold`}>
                                The Sportskins Brand
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Sportskins is the home of ‘Premskins’ the ultimate Premier League Last Player Standing
                                game and ‘Champskins’ its sister competition based on the English Championship.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                We aim to continually innovate, introduce new competitions and give value added content
                                to enhance the enjoyment of the community on a variety of sports under the ‘Sportskins’
                                umbrella.
                            </div>
                            <div className={`mb-0 mt-5 text-skins-red cabin text-start font-48 fw-bold`}>
                                Why Join SportSkins?
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                At Community Gaming Limited (Sportskins is a trading name of Community Gaming Limited )
                                we are committed to providing the opportunity for any player to have the chance to win
                                large cash prizes for a small entry fee.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Our ethos is to create a fun, entertaining and informative platform that a member can
                                engage with and become part of a larger like-minded community.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                So sign up, it’s free and download the App. Tell your friends and why not join our
                                online community to discuss your predictions or to generally talk about the sporting
                                events of the day.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Can you be the person to survive and take home the ultimate prize?
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                GOOD LUCK
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                At CGL we take our responsibilities to gambling and its effects extremely seriously
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                <a href={'/responsible-gambling'} className={'text-skins-red text-decoration-none'}>
                                    Click here to view our responsible gambling page
                                </a>
                            </div>
                        </Col>
                        {
                            !isMobile &&
                            <Col xxl={6} xl={6} lg={12} md={12} sm={false} className={'d-none d-md-block'}>
                                <Parallax
                                    bgImage={require('../assets/images/wp-content/uploads/elementor/thumbs/iphone-double-ss@3x-q3sr5jl8zsw3vha5u2jusegflh0bu2xoehbbvt2mhq.png')}>
                                    <div style={{height: '1200px'}}>
                                    </div>
                                </Parallax>
                            </Col>
                        }
                    </Row>
                </Container>
            </section>
        );
    }

    const renderSection3 = () => {
        return (
            <section className={`bg-white p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0`}>
                <HowToPlayComponent isMobile={isMobile}/>
            </section>
        );
    }

    const renderSection4 = () => {

        return (
            <section className={`${isMobile ? '' : 'bg-white'}`}>
                <div className={`${isMobile ? 'bg-dark-blue' : 'bg-skins-teal'} rounded-top-home-section-4 pb-4`}>
                    <div className={'text-center pt-5'}>
                        <h2 className={'text-white cabin font-48'}>Features</h2>
                    </div>
                    <div className={'text-center mb-5'}>
                        <p className={'text-white cabin font-20'}>
                            Check out our app, where you can view your account, check out the current prize pot, look up
                            our game rules, and much more.
                        </p>
                    </div>
            
                    <Swiper
                        ref={sliderRef}
                        slidesPerView={isMobile ? 1.5 : 5}
                        centeredSlides
                        spaceBetween={isMobile ? 5: 0} 
                        pagination={{
                            clickable: true,
                            bulletClass: 'swiper-pagination-bullet bg-white mt-5',
                        }}
                        navigation={true}
                        loop={true}
                        freeMode={false}
                        modules={[Navigation, Pagination]}
                        className="mySwiper mt-5 text-center gap-1"
                    >
                        {
                            HomeSection4Images?.map((image, index) => {
                                return (
                                    <SwiperSlide
                                        key={`home_features_images_${index}`}
                                        className={'text-white mb-5 pb-5'}>
                                        <img src={image.src} alt={`${image.alt}`} className={"img-fluid"} style={{width:"257px", height:"560px"}}/>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>






                    {/*<div className="icon" onClick={handlePrev}>
                    <div className="prev-arrow"/>
                </div>
                <div className="icon" onClick={handleNext}>
                    <div className="next-arrow"/>
                </div>*/}
                    <div className={'text-center pt-3'}>
                        <div className={'text-center'}>
                            <h2 className={'text-white cabin font-32 pb-3'}>Get SportSkins</h2>
                        </div>
                        <Row className={'text-center justify-content-center d-flex w-100'}>
                            <Col className={'mt-2 text-sm-center text-md-end'}>
                                <a
                                    href={'https://play.google.com/store/apps/details?id=uk.sportskins'}
                                    onClick={handleGooglePlay}
                                    target={'_blank'} rel={'noreferrer'}
                                >
                                    <img
                                        src={require('../assets/images/home/google_play.png')}
                                        alt={'Home Google Play'}/>
                                </a>
                            </Col>
                            <Col className={'mt-2 text-sm-center text-md-start'}>
                                <a
                                    href={'https://apps.apple.com/gb/app/sportskins-football-betting/id1668307911'}
                                    onClick={handleAppStore}
                                   target={'_blank'} rel={'noreferrer'}
                                >
                                    <img
                                        src={require('../assets/images/home/app_store.png')}
                                        alt={'Home App Store'}/>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        );
    }

    return render();
}


Home.propTypes = {};
export default Home;
