// export const BASE_URL = 'http://127.0.0.1:8009/';
export const BASE_URL = 'https://api.sportskins.uk/'; // TODO: uncomment this for going live
// export const BASE_URL = 'https://sportskins.codestudio.solutions/'; // TODO: uncomment this for going staging
export const STAGING_BASE_URL = 'https://sportskins.codestudio.solutions/';
export const FACEBOOK_LINK = 'https://facebook.com';
export const YOUTUBE_LINK = 'https://youtube.com';
export const WHATSAPP_LINK = 'https://chat.whatsapp.com/FGzZil7L4yV11HClWVUyMd';
export const INSTAGRAM_LINK = 'https://www.instagram.com/sportskinsuk/';
export const TWITTER_LINK = 'https://x.com/sportskins';
export const KYC = "https://api.w2globaldata.com/kyc-check"; //kyc production
export const CONTACT_US = "api/v1/contact";
export const LOGIN = "api/v1/user/login";
export const FORGOT_PASSWORD = "api/v1/user/forgot/password";
export const SETTINGS = "api/v1/settings?web=euro";
export const MY_SKINS = "api/v1/user/skins/picks1?status=";
export const SKINS = "api/v1/skins?type=";
export const SKIN_SINGLE = "api/v1/skins?skin_id=__SKIN_ID__";
export const WEEKS_BY_SKIN = "api/v1/skins/__SKIN_ID__/weeks";
export const MATCHES_BY_WEEK = "api/v1/skins/week/__WEEK_ID__/matches1";
export const STORE_USER_SKIN_PICKS = "api/v1/user/skins/picks";
export const BLOG_BY_SKIN_WEEK_TYPE = "api/v1/blog";
export const BLOG_BY_SLUG = "api/v1/blog/slug";
export const LATEST_BLOG_BY_SKIN = "api/v1/blog/__SKIN_ID__/latest";

export const GET_ADDRESS_DETAILS = "https://api.getAddress.io/get/__id__?api-key=__apikey__";
export const GET_POSTCODE= "https://api.getAddress.io/autocomplete/__postcode__/?api-key=__apikey__&all=true";

export const GET_ALL_TEAMS= "api/v1/teams";
export const APP_LOGS= "api/v1/app/logs";
export const REGISTER_USER= "api/v1/user/register";
export const KYC_LOG= "api/v1/w2/log/request";
export const GAMSTOP_AND_USER_CHECK= "api/v1/user/check";
export const REGISTER_CHECK= "api/v1/user/register/check";
// export const MAKE_PAYMENT_CHECKOUT= "api/v1/euro/make/payment";
export const MAKE_PAYMENT_CHECKOUT= "api/v1/temp/make/payment";
export const USER= "api/v1/user";
export const GET_LEAGUES= "api/v1/euro/leagues";
export const GET_LEAGUE_TEAMS= "api/v1/euro/leagues/__LEAGUE_ID__/teams";
export const GET_LEAGUE_GROUPS= "api/v1/euro/leagues/__LEAGUE_ID__/groups";
export const PRE_TOURNAMENT_SELECTION= "api/v1/euro/pre/tournament/selections";
export const GET_LEAGUE_MATCH_DAYS= "api/v1/euro/leagues/__LEAGUE_ID__/match-days";
export const GET_LEAGUE_MATCH_DAY_FIXTURES= "api/v1/euro/leagues/match-day/__MATCH_DAY_ID__/matches";
export const GET_PRE_TOURNAMENT_SELECTIONS= "api/v1/euro/pre/tournament/selections";
export const MATCH_DAY_SELECTIONS= "api/v1/euro/match-day/selections";
export const MATCH_DAY_JOKER_GAME= "api/v1/euro/match-day/joker/game";
export const OVER_ALL_LEADERBOARD= "api/v1/euro/overall/__LEAGUE_ID__/leaderboard";
export const GAME_LEADERBOARD= "api/v1/euro/game/__MATCH_DAY_ID__/leaderboard";
// ?page=1&limit=10
export const GET_USER_PREDICTED_SCORE= "api/v1/euro/game/__GAME_ID__/user/__USER_ID__/predictions";
