import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Frames, CardNumber, ExpiryDate, Cvv} from 'frames-react';
import {post} from "../Axios/post";
import LoginModal from "../Components/LoginModal";
import {MAKE_PAYMENT_CHECKOUT, STAGING_BASE_URL,} from "../Axios/EndPoints";
import ButtonLoader from "../Components/ButtonLoader";
import ImageBottomFooter from "../assets/images/eoro-2024/bottom-footer-euro-2024.svg";
import BottomFooter from "../Components/BottomFooter";
import CustomModal from "../Components/ModalComponent/CustomModal";

const TempPayment = (props) => {
    const {isMobile} = props;
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const store = useSelector(state => state);
    // console.log("store?.Authentication.token at top >>>", store?.Authentication.token)
    const isLogin = useSelector(state => state.Login.isLogin);
    const user = useSelector(state => state.Login);
    const settingsStore = useSelector(state => state?.AppSettings?.appSettings);

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showCustomModal, setShowCustomModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalStatus, setModalStatus] = useState("");
    const [isChecked, setIsChecked] = useState(false); // State to track checkbox status


    // Public api key for checkout
    let publicKey = settingsStore?.CHECKOUT_PUBLIC_KEY;
   

    useEffect(() => {
        if (!isLogin) {
            setShowLoginModal(true);
        } else {
            
        }
    }, [isLogin]);
   
    const submitRequest = (e) => {
        setLoading(true);
        let isStaging = parseInt(params.get('staging'));
        let formData = new FormData();
        formData.append("type", "token");
        formData.append("token", e.token);

        post(
            MAKE_PAYMENT_CHECKOUT,
            formData,
            true,
            store?.Authentication?.token,
            null,
            isStaging ? STAGING_BASE_URL : ''
        ).then(res => {
            if (res?.data?.success === true) {
                console.log("response from api is >>>", res?.data)
                // dispatch(setSelectedTeams([]));
                // dispatch(setFromPaymentScreen(false));
                if (res?.data?.data?.three_ds) {
                    window.location.href = res?.data?.data?.redirect_url;
                }else{
                    // navigate("/");
                    setShowCustomModal(true);
                    setModalMessage( res?.data?.message || "Payment Successfull");
                    setModalStatus("success");
                }
                setLoading(false);
                // dispatch(setPaymentPreparedData([]));
                // dispatch(setSelectedSkinDataAction([]));
                // alert(res?.data?.message);
                // console.log("payment done >>>res? >>>>", res?.data?.data);

            }
        }).catch((error) => {
            setLoading(false);
            setShowCustomModal(true);
            setModalMessage(error.response?.data?.message || "Some error occurred. Please try again");
            setModalStatus("error");
            console.error("error while submitting payment = ", error?.response?.data?.message)
        });
    };

    const onClickModal = () => {
        setShowCustomModal(false);
        setModalMessage("");
        setModalStatus("");
        if(modalStatus === "success"){
            navigate("/");
        }

    }


    const renderPaymentForm = () => {
        
    
        // Function to handle checkbox change
        const handleCheckboxChange = () => {
            setIsChecked(!isChecked); // Toggle checkbox state
        };
    
        return (
            <>
                <Frames
                    config={{
                        publicKey: publicKey,
                        schemeChoice: "true",
                        localization: {
                            cardNumberPlaceholder: 'Card number',
                            expiryMonthPlaceholder: 'MM',
                            expiryYearPlaceholder: 'YY',
                            cvvPlaceholder: 'CVV',
                        },
                        style: {
                            base: {
                                fontSize: '17px',
                            },
                            invalid: {
                                color: 'red', // Invalid input color
                            },
                        },
                    }}
                    cardTokenized={e => {
                        submitRequest(e);
                    }}
                    cardTokenizationFailed={error =>
                        console.error("error tokenizing =", error)
                    }
                >
                    <div className={"d-flex flex-column gap-2 mt-3"}>
                        <div className={"font-13 roboto fw-bold"}>Card Number</div>
                        <CardNumber className={"payment-input ps-3 pe-3 rounded"} />
                    </div>
                    <div className={"d-flex flex-row my-3 align-items-center justify-content-between gap-3 py-4"}>
                        <div className={"d-flex flex-column gap-2"}>
                            <div className={"font-13 roboto fw-bold"}>Exp. Date</div>
                            <ExpiryDate className={"payment-input ps-3 pe-3 rounded"} />
                        </div>
                        <div className={"d-flex flex-column gap-2"}>
                            <div className={"font-13 roboto fw-bold"}>CVV</div>
                            <Cvv className={"payment-input ps-3 pe-3 rounded"} />
                        </div>
                    </div>
                    
                    <div className={"mb-2 d-flex align-items-center justify-content-end"}>
                        <div className={"font-26 gibson fw-bold"}>Total: </div>
                        <div className={"font-26 gibson fw-bold text-red"}>£{user?.user?.pending_payment}</div>
                    </div>

                    <div className={"d-flex align-items-center mb-4"}>
                        <input
                            type="checkbox"
                            id="termsCheckbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="termsCheckbox" className="ms-2">
                            I'm using Debit Card.
                        </label>
                    </div>
    
                    <Button
                        className={'btn-skins-red w-100'}
                        onClick={() => {
                            Frames.submitCard();
                            setLoading(true);
                        }}
                        disabled={!isChecked || (user?.user?.pending_payment === 0)} 
                    >
                        {loading ? <ButtonLoader loading={loading} /> : "Pay Now"}
                    </Button>
                </Frames>
            </>
        );
    };

    const renderMobile = () => {
        return (
            <>
                <div className={'text-center fw-bold font-60 h1 text-white gibson text-uppercase'}>
                    <span className={'font-50'}>Payment</span> {' '}
                </div>
                <div className={"bg-team-card-grey rounded-my-skins-top"}>
                    <Container className={"d-flex justify-content-center"}>
                        <div
                            className={"p-4 d-flex align-items-center justify-content-between gap-4"}>
                            <div className={"d-flex flex-column align-items-start justify-content-between w-100 gap-2"}>
                                {/* <div className={"w-100"}>
                                    <div className="text-center">Note: Please make payment for your selections in PREMSKINS 2.</div>
                                </div> */}
                                {/*<div className={"payment-card-vertical-line"}></div>*/}
                                <div className={"w-100"}>
                                    {renderPaymentForm()}
                                </div>
                            </div>
                        </div>
                    </Container>
                    <div>
                        <img
                            className={"img-fluid"}
                            src={ImageBottomFooter}
                            alt={"bottom-border"}
                        />
                    </div>
                    <div className={"pt-3 pb-1"}>
                        <BottomFooter isPayment={true}/>
                    </div>
                </div>

            </>
        );
    }

    const renderDesktop = () => {
        return (
            <>
                <div>
                    <Container className={"w-50 d-flex justify-content-center align-items-center"}>
                        <div
                            className={"register-desktop p-4 d-flex align-items-center justify-content-center w-100 gap-4"}
                        >
                            <Row>
                                <Col className={"col-12 w-100 pb-3"}>
                                    <div className={"ps-2 h-100 d-flex flex-column justify-content-between"}>
                                        <div className={'text-center w-100 fw-bold font-60 h1 text-blue gibson text-uppercase'}>
                                            <span className={'font-50'}>Payment</span>{' '}
                                        </div>
                                        <div className={"w-100"}>
                                            {renderPaymentForm()}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div>
                        <img
                            className={"img-fluid"}
                            src={ImageBottomFooter}
                            alt={"bottom-border"}
                        />
                    </div>
                    <div className={"pt-3 pb-1"}>
                        <BottomFooter/>
                    </div>
                </div>
            </>
        );
    }
    const render = () => {
        return (
            <section
            >
                <Helmet>
                    <title>Sportskins - Payment-2024</title>
                    <meta name="description" content="Sportskins- Payment-2024"/>
                </Helmet>
                <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal}/>
                {isMobile ? renderMobile() : renderDesktop()}

                {
                    showCustomModal &&
                    <CustomModal
                        showModal={showCustomModal}
                        setShowModal={setShowCustomModal}
                        message={modalMessage}
                        modalStatus={modalStatus}
                        onClick={onClickModal}
                    />
                }

            </section>
        );
    }

    return render();
}

export default TempPayment;
